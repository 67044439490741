<template>
  <div>
    <div class="container">
      <div class="list list3">
        <div class="listitem2">
          <div class="listbox">
            <div class="name">有无基础疾病</div>
          </div>
          <div class="ts">{{zwflag}}</div>
          <div class="listbox1">           
            <div v-if="flag == false"><img src="@/assets/kgoff.png" @click="onKg" class="iconkg" /></div>
            <div v-if="flag == true"><img src="@/assets/kgon.png" @click="onKg" class="iconkg" /></div>            
          </div>        
        </div>
      </div>
      <div v-if="flag == true">
      <div class="list list5">
        <div class="listitem2">
          <div class="listbox">
            <div class="name">高血压</div>
          </div>
          <div class="listbox1">
            <div v-if="gxyflag == false"><img src="@/assets/off.png" @click="onGxy" class="iconkg1" /></div>
            <div v-if="gxyflag == true"><img src="@/assets/on.png" @click="onGxy" class="iconkg1" /></div>
          </div>        
        </div>
      </div>
      <div class="list list2">
        <div class="listitem2">
          <div class="listbox">
            <div class="name">糖尿病</div>
          </div>
          <div class="listbox1">
            <div v-if="tnbflag == false"><img src="@/assets/off.png"  @click="onTnb" class="iconkg1" /></div>
            <div v-if="tnbflag == true"><img src="@/assets/on.png"  @click="onTnb" class="iconkg1" /></div>
          </div>        
        </div>
      </div>
      <div class="list list2">
        <div class="listitem2">
          <div class="listbox">
            <div class="name">心脑血管疾病</div>
          </div>
          <div class="listbox1">
            <div v-if="xnxgflag == false"><img src="@/assets/off.png"  @click="onXnxg" class="iconkg1" /></div>
            <div v-if="xnxgflag == true"><img src="@/assets/on.png"  @click="onXnxg" class="iconkg1" /></div>
          </div>        
        </div>
      </div>
      <div class="list list2">
        <div class="listitem2">
          <div class="listbox">
            <div class="name">哮喘</div>
          </div>
          <div class="listbox1">
            <div v-if="xcflag == false"><img src="@/assets/off.png"  @click="onXc" class="iconkg1" /></div>
            <div v-if="xcflag == true"><img src="@/assets/on.png"  @click="onXc" class="iconkg1" /></div>
          </div>        
        </div>
      </div>
      </div>
      <div class="list_ll list2">
        <div class="ll">
          <div class="button primary mt" @click="submit">提交</div>         
        </div>
        
      </div>

    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
export default {
  async created() {
    let { wxdj_uuid,gxy,tnb,xnxgjb,xc } = this.$route.query;
    this.wxdj_uuid = wxdj_uuid;
    this.gxy = gxy;
    this.tnb = tnb;
    this.xnxgjb = xnxgjb;
    this.xc = xc; 

    //alert('gxy:'+gxy+',tnb:'+tnb+',xnxgjb:'+xnxgjb+',xc:'+xc);

    if(this.gxy == '1'){
      this.gxyflag = true;
    }else{
      this.gxyflag = false;
    }
    if(this.tnb == '1'){
      this.tnbflag = true;
    }else{
      this.tnbflag = false;
    }
    if(this.xnxgjb == '1'){
      this.xnxgflag = true;
    }else{
      this.xnxgflag = false;
    }
    if(this.xc == '1'){
      this.xcflag = true;
    }else{
      this.xcflag = false;
    }
    if(this.gxyflag||this.tnbflag||this.xnxgflag||this.xcflag){
      this.flag = true;
      this.zwflag='有';        
    }

  },
  data() {
    return {
      data: {
        kgflag: false
      },
      wxdj_uuid: '',
      gxy:'2',
      tnb:'2',
      xnxgjb:'2',
      xc:'2',
      zwflag: '无',
      gxyflag: false,
      tnbflag: false,
      xnxgflag: false,
      xcflag: false,
      flag: false
    };
  },
  methods: {
    onKg() {
      this.flag=!this.flag; 
      if(this.flag){
        this.zwflag='有';
      }else{
        this.zwflag='无';
      }    
    },
    onGxy() {
      this.gxyflag=!this.gxyflag; 
    },
    onTnb() {
      this.tnbflag=!this.tnbflag;
    },
    onXnxg() {
      this.xnxgflag=!this.xnxgflag;
    },
    onXc() {
      this.xcflag=!this.xcflag;  
    },
    async submit(){  

      if(this.gxyflag){
        this.gxy = '1';
      }else{
        this.gxy = '2';
      }
      if(this.tnbflag){
        this.tnb = '1';
      }else{
        this.tnb = '2';
      }
      if(this.xnxgflag){
        this.xnxgjb = '1';
      }else{
        this.xnxgjb = '2';
      }
      if(this.xcflag){
        this.xc = '1';
      }else{
        this.xc = '2';
      }

      //this.$router.push('/assistregister/submit_success?gxy=' + this.gxy +'&tnb=' + this.tnb +'&xnxgjb=' + this.xnxgjb +'&xc=' + this.xc+'&wxdj_uuid=' + this.wxdj_uuid);
      
      let { data } = await this.axios.post(
        '/ctid/TlZhService/pz_updatelnb',
        qs.stringify({
          wxdj_uuid: this.wxdj_uuid,
          gxy: this.gxy,
          tnb: this.tnb,
          xnxgjb: this.xnxgjb,
          xc: this.xc
        })
      );
      if (data.flag === '0') {
        //Toast('提交成功!');
        Dialog({ message: "<img src='/img/cg.png' width=100px height=60px/></br>"+data.message });
        //this.$router.push('/assistregister/submit_success?name=' + data.data.name +'&idnum=' + data.data.idnum +'&mobile=' + data.data.mobile +'&number_car=' + data.data.number_car +'&picture=' + data.data.picture +'&picture_ewm=' + data.data.picture_ewm +'&wxdj_uuid=' + data.data.wxdj_uuid);
        this.$router.push('/assistregister/submit_success?wxdj_uuid=' + this.wxdj_uuid);
      }else {
        Dialog({ message: "<img src='/img/sb.png' width=100px height=60px/></br>"+data.message });
      }
    }
    
  }
};
</script>

<style lang="less" scoped>
.icon1 {
  width: 64px;
  height: 64px;
  display: block;
}
.bg {
  height: 503px;
}
.content {
  margin-top: 60px;
}
.list {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  &.list2 {
    margin-top: 20px;
  }
  &.list3 {
    margin-top: 50px;
  }
  &.list5 {
    margin-top: 30px;
  }
  .listitem {
    overflow: hidden;
    height: 100px;
    border-radius: 8px;
    .icon {
      width: 64px;
      margin: 18px 0 18px 40px;
      display: block;
      float: left;
    }
    .iconkg {
      width: 100px;
      margin: 18px 0 18px 40px;
      display: block;
      float: left;
    }
    .arrow {
      float: right;
      display: block;
      margin-top: 32px;
      margin-right: 38px;
      height: 40px;
    }
    .text {
      font-size: 32px;
      float: left;
      margin-left: 20px;
      line-height: 44px;
      margin-top: 30px;
    }
    .right {
      line-height: 40px;
      color: #666666;
      font-size: 28px;
      float: right;
      margin-top: 32px;
      margin-right: 38px;
    }
    .input {
      margin-left: 15px;
      border: 0;
      color: #333;
      margin-top: 34px;
      font-size: 32px;
    }
  }
  .listitem2 {
    overflow: hidden;
    height: 100px;
    border-radius: 8px;
    display: flex;
    &.mt {
      height: 270px;
    }
    .listbox {
      flex: 1;
      overflow: hidden;
      .red {
        color: rgb(180, 4, 4);
      }
      .name {
        height: 60px;
        line-height: 60px;
        overflow: hidden;
        font-size: 35px;
        font-weight:800;
        margin-top: 20px;
        margin-left: 34px;
      }
      .name1 {
        height: 60px;
        line-height: 60px;
        overflow: hidden;
        font-size: 35px;
        font-weight:800;
        margin-top: 52px;
        margin-left: 34px;
      }
      .unames {
        height: 30px;
        line-height: 30px;
        font-size: 28px;
        color: #666666;
        margin-top: 30px;
        margin-left: 36px;
      }
      .unames1 {
        height: 30px;
        line-height: 30px;
        font-size: 28px;
        color: #666666;
        margin-top: 10px;
        margin-left: 36px;
      }
      
    }
    .ts {
      height: 60px;
      width: 50px;
      line-height: 60px;
      overflow: hidden;
      font-size: 30px;
      margin-top: 22px;
      margin-right: 0px;
      display: block;
      float: right;
      
    }
    
    .listbox1 {
  
      overflow: hidden;
      .unames {
        height: 60px;
        line-height: 60px;
        font-size: 28px;
        color: #c90c0c;
        margin-top: 22px;
        margin-right: 34px;
        float: right;
      }
      .iconkg {
        width: 120px;
        margin-top: 12px;
        margin-right: 20px;
        display: block;
        float: right;
      }
      .iconkg1 {
        width: 50px;
        margin-top: 25px;
        margin-right: 34px;
        display: block;
        float: right;
      }
      

      
    }
    
  }
  
}
.list_ll {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  &.list2 {
    margin-top: 15px;
  }
  .ll {
    width: 90%;
    margin: 0px auto;
  }
  
}
.info {
  overflow: hidden;
  margin: 90px 54px 62px;
  img {
    width: 144px;
    height: 144px;
    border-radius: 144px;
    display: block;
    float: left;
  }
  .detail {
    overflow: hidden;
    margin-left: 44px;
    float: left;
    .name {
      margin-top: 20px;
      line-height: 66px;
      color: #fff;
      font-size: 48px;
    }
    .tel {
      height: 40px;
      font-size: 28px;
      font-weight: 400;
      line-height: 40px;
      color: rgba(255, 255, 255, 1);
      opacity: 0.6;
    }
  }
}
</style>